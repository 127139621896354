
import {defineComponent, computed, ref, onMounted} from 'vue';
import {useStore} from '@/store'
import {AuthenticationMutationTypes} from "@/store/modules/authentication/mutation-types";
import globalMixin from '@/mixins/globalMixin';

export default defineComponent({
  name: 'LoginPage',
  mixins: [globalMixin],
  setup() {
    const store = useStore();
    const username = ref('' as string);
    const password = ref('' as string);
    const showPassword = ref(false as boolean);
    const user = {};
    const isError = computed(() => store.getters.getIsErrorAuthentication);

    function postSignIn() {
      const payload = {username: username.value, password: password.value};
      store.commit(AuthenticationMutationTypes.POST_SIGN_IN, payload)
    }

    function showHidePassword() {
      let input = document.getElementsByClassName('password-input')[0];
      if (input.getAttribute('type') === 'password') {
        showPassword.value = true;
        input.setAttribute('type', 'text')
      } else {
        showPassword.value = false;
        input.setAttribute('type', 'password')
      }
    }

    return { user, username, password, showPassword, isError, postSignIn, showHidePassword };
  },
  mounted(): void {
    let loggedUser = localStorage.getItem('loggedUser');
    let cid = localStorage.getItem('cid');
    if (loggedUser && cid) {
      this.$router.push('search')
    }
  }
})
